.app_btn {
    position: relative;
    display: inline-block;
    border-radius: 25px;
    font-size:13px;
    text-decoration: none;
    padding:17px 35px;
    font-family: var(--font-bold);
    color: #1c1c1c;
    text-align: center;
    transition: background 300ms ease-in-out;
    cursor: pointer;
    text-transform: uppercase;
    &[data-disabled=true]   {
        opacity:0.3;
        pointer-events: none;
        user-select: none;
    }
    &[data-inline="false"] {
        width:100%;
    }
    &:focus {
        box-shadow: 0 0 8px 0 rgba(0,0,0,0.2);
    }
    &[data-variant="primary"] {
        background: var(--primary-color-orange);
        &:hover {
            background: #eaad00;
        }
        &:focus {
            outline:none;
            background: #eaad00;
            
        }
    }
    &[data-variant="yellow"] {
        background: #fff;
        border:1px solid var(--primary-color-orange);
        &:hover {
            background:#f1f1f1;
        }
        &:focus {
            outline:none;
            background:#f1f1f1;
        }
    }
    &[data-variant="secondary"] {
        background: #fff;
        border:1px solid #b9b8b8;
        &:hover {
            background:#f1f1f1;
        }
        &:focus {
            outline:none;
            background:#f1f1f1;
        }
    }
    &[data-variant="green"] {
        background: var(--primary-color-green);
        color: #fff;
        border:1px solid var(--primary-color-green);
        &:hover {
            background:var(--primary-color-green);
        }
        &:focus {
            outline:none;
            background:var(--primary-color-green);
        }
    }
    &[data-variant="green-inverse"] {
        background: #fff;
        color: var(--primary-color-green);
        border:1px solid var(--primary-color-green);
        &:hover {
            background:#fff;
        }
        &:focus {
            outline:none;
            background:#fff;
        }
    }
    &[data-loading="true"] {
        background: #b9b8b8 !important;
        color:rgba(0,0,0,0.5) !important;
        pointer-events: none;
        user-select: none;
        cursor:wait;
        transition: background 100ms ease-in-out;
    }
    &[data-loading="true"]:after {
        
        display: inline-block;
        content:""  !important;
        width:10px;
        height:10px;
        border-radius: 50%;
        vertical-align: middle;
        border:3px solid rgba(255,255,255,0.3);
        border-top-color: #fff;
        margin-left:10px;
        transform:translateY(-50%);
        animation: spin linear 500ms infinite;
        transform-origin: center center;
        background: none  !important;
    
    }
    &.thin{
        padding: 10px 35px;
        text-transform: capitalize;
    }
    .btn_icon{
        margin-right: 16px;
    }
    
}


@keyframes spin {
    0%{
        transform: rotate(-359deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

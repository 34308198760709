@import "../../../../styles/base/layout";

.parent_container {
    display: flex;
    padding: 24px 12px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    justify-content: center;
    margin: auto;
    margin-bottom: 40px;
    background: #FAFAFA;
    width: 646px;
    .content{
        text-align: center;
        .heading{
            color: #000;
            text-align: center;
            font-variant-numeric: lining-nums proportional-nums;
            font-family: var(--font-semi-bold);
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 8px;
        }
    }
    @media (max-width: map-get($grid-breakpoints, lg)) {
        margin: 0px 16px 20px 16px;
        width: auto;
    }
}